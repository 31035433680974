<template>
  <div v-if="user">
    <v-card>
      <v-card-title primary-title>
        <h3>You have received all doses. </h3>
      </v-card-title>
      <v-card-text>
        <v-divider/>
        <v-row>
          <v-col cols="12">
            <h3>No further scheduling is required.  Thank you.</h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    user: {
      type: Object,
      default: () => null,
      required: true
    },
  },
};
</script>
